<template>
    <div :class="cardView? 'card-container-report': 'container-report container-fluid'" class="mt-2" :id="_uid" v-if="isMounted">
        <div class="form-row card rounded-0">
            <div class="col-12 my-2" v-if="!hideFilters">
                <div v-for="(filterRow, i) of editorRows" class="form-material report-filters"
                  :class="{'d-flex justify-content-left': !mobile}">
                  <div v-for="field of filterRow" class="px-1" :class="getFieldColumns(field)" v-if="!field.hidden">
                    <input-field
                          v-if="!hideIf(field)"
                          :field="field"
                          :defValue.sync="current[field.name]"
                          :invalid="invalid[field.name]"
                          :fieldOptions="fieldOptions[field.name]"
                          :record="current"
                          :report="true"
                          @change="updateCurrent(field.name)"
                      ></input-field>
                  </div>
                  <div v-if="i == 0 && moreFilters" @click="moreFilters = false" class="cursor-pointer">
                      ...
                  </div>
              </div>
            </div>
            <div class="form-row my-2" v-if="!cardView">
                <div class="ml-3">
                    <h6 class="text-muted asterisk">* {{tr('Required')}}</h6>
                </div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-left mb-3" v-if="!cardView">
                <div class="col-8 d-flex align-items-center justify-content-left" v-if="!cardView && !emb">
                    <button class="btn btn-primary ml-3 report-button" @click="run">
                        <font-awesome-icon icon="play"/>
                    </button>
                    <button class="btn btn-primary ml-2 report-button" @click="download" :disabled="!dataList || buttonsDisabled.download" v-if="!hideExcel">
                        <font-awesome-icon icon="file-excel"/>
                    </button>
                    <button class="btn btn-primary ml-2 report-button" @click="downloadCSV" :disabled="!dataList || buttonsDisabled.downloadCSV"  v-if="!hideCSV">
                        <font-awesome-icon icon="file-csv"/>
                    </button>
                    <button class="btn btn-primary ml-2 report-button" @click="downloadPDF" :disabled="!dataList || buttonsDisabled.downloadPDF" v-if="pdf">
                        <font-awesome-icon icon="file-pdf"/>
                    </button>
                    <!--button class="btn btn-primary ml-2 report-button" @click="addToDashboard" :class="{'btn-success': inDashboard}" :disabled="buttonsDisabled.addToDashboard" v-if="!hideDashboard">
                        <font-awesome-icon icon="home"/>
                    </button-->
                    <button class="btn btn-primary ml-2 report-button" v-if="allowChart && !showChart" @click="showChart = !showChart" :disabled="!dataList || buttonsDisabled.chart">
                        <font-awesome-icon icon="chart-bar"/>
                    </button>
                    <button class="btn btn-primary ml-2 report-button" v-if="allowChart && showChart" @click="showChart = false">
                        <font-awesome-icon icon="table"/>
                    </button>
                    <button class="ml-2 report-button" :class="b.buttonClass" @click="buttonCallback(b.callback, b.id)"
                        v-for="b of buttons"
                        :disabled="!list || b.disabled || processingCallback[b.id]">
                            <span v-if="!processingCallback[b.id]">{{tr(b.label)}}</span>
                            <img class="processing-gif" src="@/img/spin.png" v-else>
                    </button>
                    <!--div class="col-4 form-material" v-if="showChart">
                      <select-input fieldName="chartType"
                        :fieldOptions="[{value: 'bars', label: 'Bars'}, {value: 'pie', label: 'Pie'}]"
                        :currentValue.sync="chartType" label="Chart Type">
                      </select-input>
                    </div>
                    <div class="col-4 form-material" v-if="showChart && chartOptionsY">
                      <select-input fieldName="serialY"
                        :fieldOptions="chartOptionsY"
                        :currentValue.sync="serialY" label="Serial Y">
                      </select-input>
                    </div-->
                    <!--div class="col-4 form-material" v-if="chartOptionsFx">
                      <select-input fieldName="serialFx"
                        :fieldOptions="chartOptionsFx"
                        :currentValue.sync="serialFx" label="Function">
                      </select-input>
                    </div-->
                </div>
                <div class="col-8" v-else>
                </div>
                <!--div class="col-4" v-if="!hideSearch && !showChart">
                    <search-box class="" v-model="search" ></search-box>
                </div-->
            </div>
        </div>
        <div class="card rounded-0 card-body pt-0 mt-2" v-if="!showChart">
            <div class="card-header" style="background-color: #fff">
                <input type="search" :placeholder="tr('Search')"  v-if="!hideSearch" v-model="search"
                aria-label="Search" class="form-control form-search">
                <h6 class="font-weight-bold mb-2 mt-2">{{title}}</h6>
            </div>
        </div>
        <div class="row my-1" v-if="message || errors.length>0">
            <div class="col-12 d-flex justify-content-center">
                <span v-if="message" class="alert alert-warning" role="alert">
                    {{tr(message)}}
                </span>
                <span v-else-if="errors.length>0" class="alert alert-danger" role="alert">
                    {{errors.join('; ')}}
                </span>
            </div>
        </div>
        <div class="card rounded-0 card-body pt-0 mt-2"
            v-if="ready && !running && list && !showChart">
            <div class="pt-2 table-max-content" id="data-table" :class="{'hide-counter': hideCounter}">

                <v-client-table :data="list" :columns="columnsToShow" :options="options" @row-click="onRowClick">
                    <template v-for="(r, rowNr) of slotsFields" slot-scope="props" :slot="r.name">
                        <row-input-field
                        :fieldOptions="getFilteredOptions(props.row, r)"
                        :disabled="r.getDisabled && r.getDisabled(props.row)"
                        :rowField="r"
                        :arrayField="{name: 'report'}"
                        :rowNr="props.index"
                        :getId="true"
                        :defValue.sync="props.row[r.name]"
                        :row="props.row"
                        @focus="focusOn"
                        @afteredit="afterEdit(r.name, props.row)"
                        ></row-input-field>
                    </template>
                    <template v-for="fieldName of notSlotFields" slot-scope="props" :slot="fieldName">
                        <span
                        :class="props.row.__total_row__? 'report-total-row': getFieldClass(props.row, fieldName)"
                        @click="columnRowClick(props.row, fieldName)"
                        @contextmenu.prevent="$refs.contextMenu.open($event, {fieldName: fieldName})"
                        >
                        {{applyTemplate(fieldName, props.row)}}
                        </span>
                    </template>
                    <span
                    data-toggle="tooltip" data-placement="top" :title="getHiddenColumns(props.row)"
                    @contextmenu.prevent="$refs.showAll.open($event)"
                    slot="__info__"
                    slot-scope="props">
                    <font-awesome-icon icon="info-circle"/>
                    </span>

                </v-client-table>
            </div>
        </div>
        <div v-if="showChart && list" class="mt-3">
            <div v-for="(c, i) of chartOptions">
                <bar-chart :chart-data="chart(c)"  :options="barChartOptions[i]" :height="cardView? '100%': 60"
                ></bar-chart>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState } from 'vuex';

import Vue from 'vue/dist/vue.esm';
import {ClientTable, Event} from 'vue-tables-2'
let theme;
Vue.use(ClientTable, [theme = 'bootstrap5']);
import barChart from '@/tools/BarChart.js'
//import pieChart from '@/tools/PieChart.js'
const inputField = importVueComp('components/tools', 'InputField');
const rowInputField = importVueComp('components/tools', 'RowInputField');

const colors = ['#EC7063', '#45B39D', '#F4D03F', '#85C1E9', '#BB8FCE', '#E5E7E9', '#2C3E50', '#FDFEFE']
const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');

export default {
    name: 'report-window',
    props: {
        'endpoint': String,
        'fields': Array,
        'title': String,
        'headers': Object,
        'templates': Array,
        'columnsClasses': Object,
        'cellClasses': Object,
        'perPage': Number,
        'hideFilters': Boolean,
        'afterRun': Function,
        'afterRender': Function,
        'beforeRender': Function,
        'runMounted': Boolean,
        'cardView': Boolean,
        'reportViewClass': String,
        'colsView': String,
        'notFilterable': Boolean,
        'slotsFields': Array,
        'componentFields': Array,
        'callbacks': Object,
        'current': Object,
        'headerColumns': Array,
        'buttons': Array,
        'totalsOn': Array,
        'averageOn': Array,
        'countOn': Array,
        'totalLabel': Array,
        'averageLabel': Array,
        'hideFilteredFields': Boolean,
        'hideSearch': Boolean,
        'fieldClasses': Object,
        'fieldStyles': Object,
        'columnsFormat': Object,
        'rowClick': Function,
        'columnClick': Object,
        'disableSort': Boolean,
        'allowChart': Boolean,
        'hideBlankColumns': Boolean,
        'updateReport': Boolean,
        'errorCallback': Function,
        'getFileName': Function,
        'normalize': Boolean,
        'afterDownload': Function,
        'emb': Boolean,
        'chartOptions': Array,
        'requestTimeout': Number,
        'footerHeadings': Boolean,
        'tableClass': String,
        'displayFilterValues': Object,
        'headerSticky': Boolean,
        'hideExcel': Boolean,
        'hideDashboard': Boolean,
        'hideConfig': Boolean,
        'hideCSV': Boolean,
        'simpleTable': Boolean,
        'pdf': Boolean,
        'showMore': Boolean,
        'translateHeaders': {
            type: Boolean,
            default: true
        },
        'buttonsDisabled': {
            type: Object,
            default(props) {
                return {}
            }
        },
        'fileName': String,
        'columnStyles': Object,
        'tableWidth': String,
        'hideHeader': Boolean,
        'showDefaultChart': Boolean
    },
    components: {
        ClientTable,
        'bar-chart': barChart,
        'input-field': inputField,
        'row-input-field': rowInputField,
        //'pie-chart': pieChart,
    },
    data () {
        return {
            ready: true,
            showTotals: true,
            running: false,
            processingCallback: {},
            fieldOptions: {},
            getInvalidClass: {},
            isMounted: false,
            search: null,
            dataList: null,
            dataChart: null,
            message: null,
            errors: [],
            invalid: {},
            filterColumns: false,
            columnsNames: [],
            serverColumnsHeader: [],
            hiddenFilterColumnNames: null,
            hiddenColumns: [],
            hideCounter: false,
            lastValue: null,
            lastEdited: null,
            module: null,
            dataCollection: null,
            showChart: false,
            chartType: 'bars',
            serialY: null,
            serialFx: null,
            reportName: null,
            inDashboard: false,
            vHidden: false,
            moreFilters: false,
            cardColumns: null,
            reportTemplates: null,
            setRowSpan: true,
            options: {
                footerHeadings: this.footerHeadings,
                filterable: false,
                perPageValues: [10, 20, 50, 100, 500, 1000],
                perPage: this.perPage? this.perPage: 100,
                headings: this.getHeadings(),
                texts: vueTools.vueTableText(),
                templates: this.getTemplates(),
                skin: 'table table-striped table-sm table-bordered table-bordered-hover',
                columnsClasses: this.columnsClasses? this.columnsClasses: {},
                cellClasses: this.cellClasses? this.cellClasses: {},
                customSorting: {},
                rowClassCallback: (row) => {
                  if (row.class) return row.class;
                }
            }
        }

    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            processing: state => state.main.processing,
            mobile: state => state.main.mobile,
        }),
        columns () {
            return 12 / this.cols;
        },
        editorRows () {
            let f = Object.assign([], this.filterFields);
            if (f.length <= 8) {
                return [f]
            }
            let k = 2;
            if (f.length > 16) k = 3;
            return _.chunk(f, Math.round( f.length / k));
        },
        filterFields () {
            return tools.getAbmFields(this.fields);
        },
        viewClass () {
            let res = [];
            if (this.reportViewClass) {
                //res.push('card container-fluid report-view')
                res.push(this.reportViewClass)
            };
            if (!this.cardView) res.push('mt-2');
            return res.join(' ');
        },
        columnsToShow () {
            if (!this.hideBlankColumns) return this.columnsNames;
            let res = [];
            for (let c of this.columnsNames) {
                let found = false;
                for (let row of this.list) {
                    if (row[c]) {
                        found = true;
                        break;
                    }
                }
                if (found) {
                    res.push(c)
                }
            }
            return res;
        },
        list () {
            let self = this;
            if (!this.dataList) return;
            if (this.search) {
                let values = this.search.split(' ')
                let res = _.filter(this.dataList, (r) => {
                    if (r.calculated) return true;
                    for (let value of values){
                        let found = false;
                        let re = new RegExp(tools.normalize(value), 'i')
                        for (let f in this.options.headings) {
                            let displayValue = r[f];
                            if (self.templates) {
                                let template = _.find(self.templates, (t) => t.name==f);
                                if (template) {
                                    displayValue = template.callback(r, self.dataList, f);
                                }
                            }
                            if (self.displayFilterValues && self.displayFilterValues[f]) {
                                displayValue = self.displayFilterValues[f](r[f]);
                            }
                            if (displayValue){
                                let m = tools.normalize(displayValue).match(re);
                                if (m) found = true;
                            }
                        }
                        if (!found) return false;
                    }
                    return true;
                })
                return this.applyTotals(res);
            }
            return this.applyTotals(this.dataList);
        },
        reportCols () {
            if (this.colsView) return this.colsView;
            if (this.cardView) return 'col-12';
            if (!this.cardView) return 'col-12';
            return 'col-12';
        },
        notSlotFields () {
            let res = [];
            for (let c of this.columnsNames) {
                if (c == '__info__') continue;
                let f = _.find(this.slotsFields, (r) => r.name == c);
                if (!f) f = _.find(this.componentFields, (r) => r.name == c);
                if (!f) {
                    res.push(c);
                }
            }
            return res;
        },
        chartOptionsY () {
            if (Array.isArray(this.chartOptions.y)) {
                return _.map(this.chartOptions.y, (r) => {
                    return {label: r, value: r}
                });
            }
        },
        chartOptionsFx () {
            /*if (this.chartOptions.fx && Array.isArray(this.chartOptions.fx)) {
                return _.map(this.chartOptions.fx, (r) => {
                    return {label: tr(r), value: r}
                });
            }*/
        },
        barChartOptions () {
            let values = {};
            for (let i in this.chartOptions) {
                let chartOptions = this.chartOptions[i];
                let res = {
                    tooltips: {
                        callbacks: {
                            label: (tooltipItem, data) => {
                                let indexName = chartOptions.x[tooltipItem.datasetIndex];
                                var label = data.datasets[tooltipItem.datasetIndex].label || '';
                                if (label) {
                                    label += ': ';
                                }
                                let value = tools.toNumber(tooltipItem.yLabel, 1);
                                if (!chartOptions.beforeLabel && !chartOptions.afterLabel) {
                                    label+= ' ' + value;
                                }
                                if (chartOptions.beforeLabel && chartOptions.beforeLabel[indexName]) {
                                    label += chartOptions.beforeLabel[indexName] + value;
                                }
                                if (chartOptions.afterLabel && chartOptions.afterLabel[indexName]) {
                                    label += value + chartOptions.afterLabel[indexName];
                                }
                                return label;
                            }
                        }
                    }
                }
                values[i] = res;
            };
          return values;
        },
        filtersCnt () {
            let res = 0;
            for (let field of this.fields) {
                if (this.current[field.name]) res += 1;
            }
            return res;
        }

    },
    asyncComputed: {
        async fieldOptionsTable() {
            if (!this.slotsFields) return [];
            let res =  await tools.calculateFieldOptions(this.slotsFields);
            return res;
        },
    },
    async mounted () {
        this.setCurrentFromLocalStorage();
        this.reportName = this.getParentName(this.$parent);
        this.reportTemplates = this.getTemplates();
        this.fieldOptions = await tools.calculateFieldOptions(this.filterFields);
        let pathList = this.$router.currentRoute.path.split('/');
        this.pathName = pathList[pathList.length-1];
        this.isMounted = true;
        let r = this.getInDashboard();
        this.cardColumns = this.getCardColumns();
        this.inDashboard = r.index > -1;
        if (this.showMore) this.moreFilters = true;
        if (this.runMounted) {
            await this.$nextTick();
            this.run();
        }
    },
    methods: {
        chartDataList (chartOptions) {
            let template = _.find(this.templates, (t) => t.name==chartOptions.y);
            let self = this;
            let res = _.map(this.dataChart, (r) => {
                  let v = r[chartOptions.y];
                  let row = {};
                  if (template) {
                      row[chartOptions.y] = r[chartOptions.y];
                      v = template.callback(row, self.dataList, chartOptions.y);
                  }
                  return v;
              });
            return res;
        },
        chartDataValues (chartOptions) {
          let res = [];
          if (!chartOptions) return res;
          for (let i in chartOptions.x) {
            let color = colors[i];
            let r = {
                label: [this.options.headings[chartOptions.x[i]]],
                backgroundColor: color,
                data: _.map(this.dataChart, (r) => {
                  return r[chartOptions.x[i]]
                }),
                scales: {
                    xAxes: [{
                        ticks: {
                            display: false // Hide x-axis labels
                        },
                        label: {
                            display: false,
                        }
                    }],
                },
            }
            if (chartOptions.types && chartOptions.types[i]) {
                r.type = chartOptions.types[i].type;
                r.fill = chartOptions.types[i].fill;
            }
            if (chartOptions.colors && chartOptions.colors[i]) {
                r.backgroundColor = chartOptions.colors[i];
            }
            res.push(r)
          }
          return res;
        },
        async run () {
            this.vHidden = true;
            tools._toExcel = false;
            this.showTotals = true;
            localStorage.setItem('lastPath', this.$router.currentRoute.path);
            this.showChart = this.showDefaultChart;
            this.setCurrentLocalStore()
            this.options.perPage = this.perPage? this.perPage: 100;
            this.dataList = [];
            //let sep = ','
            //if (this.user && this.user.DecimalSeparator) sep = this.user.DecimalSeparator;
            this.message = null;
            this.errors = [];
            let required = tools.checkFields(this, this.current);
            if (required) return;
            if (this.requestTimeout) api.requestTimeout = this.requestTimeout;
            api.setProcessing(true);
            this.running = true;
            let filters = {};
            let current = _.cloneDeep(this.current);
            for (let i in this.current) {
                if (!this.current[i]) continue;
                filters[i] = this.current[i];
                if (Array.isArray(this.current[i])) {
                    filters[i] = this.current[i].join(',');
                }
            }
            let res = await api.get(this.endpoint, filters, this.handleError);
            api.requestTimeout = null;
            let self = this;
            if (res) {
                this.dataList = res.rows
                if (this.normalize) {
                    this.dataList = _.map(this.dataList, (row) => {
                        let obj = {};
                        for (let key in row) {
                            let val = row[key];
                            obj[key] = tools.normalize(val, true);
                        }
                        return obj;
                    })
                }
                if (this.dataList.length>0) {
                    if (this.dataList[0].id == undefined) {
                        for (let i=0; i<this.dataList.length; i++) {
                            this.dataList[i].id = i;
                        }
                    }
                }
                this.serverColumnsHeader = res.header;
                if (this.beforeRender) {
                    await this.beforeRender(this)
                    this.reportTemplates = this.getTemplates();
                }
                let cols = this.headerColumns? this.headerColumns: this.serverColumnsHeader;
                this.options.columnsClasses = this.columnsClasses? this.columnsClasses: {};
                this.columnsNames = _.cloneDeep(cols);
                this.options.headings = this.getHeadings();

                this.dataChart = Object.assign([], this.dataList);


                if ((this.totalsOn && this.totalsOn.length>0) || this.countOn || this.averageOn) {
                    this.hideCounter = true;
                }

                if ((this.totalsOn && this.totalsOn.length>0)|| this.countOn) {
                    if (!this.disableSort) {
                        this.options.customSorting = this.getCustomSorting(this.columnsNames);
                        this.options = Object.assign({}, this.options);
                    }
                }
                if (this.disableSort) {
                    this.options.customSorting = this.disableSorting(this.columnsNames);
                }
                if (this.afterRun) {
                    this.afterRun(this)
                }
            }
            api.setProcessing(false);
            this.running = false;
            if (this.afterRender) {
                let interval = setInterval(() => {
                    let table = $('.table-responsive');
                    if (table.length > 0) {
                        this.afterRender(this)
                        this.vHidden = false;
                        clearInterval(interval);
                    }
                }, 100);
            } else {
                this.vHidden = false;
            }

        },
        applyChartFunctions () {
            let res = [];
            let values = {};
            return res;
        },
        setCurrentLocalStore () {
          let r = {
            filters: this.current,
            path: this.$router.currentRoute.path
          }
          localStorage.setItem('reportFilters', JSON.stringify(r));
        },
        setCurrentFromLocalStorage () {
          let r = localStorage.getItem('reportFilters');
          if (r) {
            r = JSON.parse(r);
            let lastPath = localStorage.getItem('lastPath');
            if (this.$router.currentRoute.path == r.path && r.path != lastPath) {
              this.$emit('update:current', r.filters);
              this.run();
            }
          }
        },
        async download () {
        	if (this.options.perPage < this.dataList.length) {
              this.options.perPage = this.dataList.length;
          }
          if (!this.simpleTable) {
              tools._toExcel = true;
              this.ready = false;
              setTimeout(() => {
                  this.ready = true;
              }, 1);
          }
          let i = setInterval(() => {
              let table = $('#data-table');
              if (table.length > 0) {
                  let fileName = 'data.xls'
                  if (this.title) fileName = tr(this.title) + '.xls';
                  if (this.fileName) fileName = this.fileName + '.xls';
                  if (this.getFileName) {
                      fileName = this.getFileName('xls')
                  }
                  if (!this.simpleTable) {
                      tools.downloadReport('data-table', fileName);
                  } else {
                      tools.exportToExcel('simpleTable', fileName)
                  }
                  if (this.afterDownload) this.afterDownload();
                  if (!this.simpleTable) {
                      this.ready = false;
                      setTimeout(() => {
                          tools._toExcel = false;
                          this.ready = true;
                      }, 1000);
                  }
                  clearInterval(i);
              }
          }, 200);

        },
        async downloadPDF () {
        	if (this.options.perPage < this.dataList.length) {
              this.options.perPage = this.dataList.length;
          }
          if (this.columnStyles) {
              this.ready = false;
              this.setRowSpan = false;
              setTimeout(() => {
                  this.ready = true;
              }, 1);
          }
          let i = setInterval(() => {
              let table = $('#data-table');
              if (table.length > 0) {
                  let fileName = 'data'
                  if (this.title) fileName = tr(this.title);
                  if (this.fileName) fileName = this.fileName;
                  if (this.getFileName) {
                      fileName = this.getFileName('pdf')
                  } else {
                    fileName += '.pdf'
                  }
                  let id = 'data-table';
                  if (this.simpleTable) {
                      id = 'simpleTable';
                  }
                  tools.exportToPDF('simpleTable', fileName, this.columnStyles? this.columnStyles: {}, 
                    this.tableWidth? this.tableWidth: 'wrap',
                    this.current.pageSize? this.current.pageSize: 'a4'
                  );
                  if (this.afterDownload) this.afterDownload();
                  if (this.columnStyles) {
                      this.ready = false;
                      this.setRowSpan = true;
                      setTimeout(() => {
                          this.ready = true;
                      }, 1000);
                  }
                  clearInterval(i);
              }
          }, 200);

        },

        downloadCSV () {
        	api.setProcessing(true);
        	this.options.perPage = this.dataList.length;
        	setTimeout(() => {
        	    api.setProcessing(false);
        	}, 1);
            let i = setInterval(() => {
                let table = $('#data-table');
                if (table.length > 0) {
                    let fileName = 'data.csv'
                    if (this.title) fileName = tr(this.title) + '.csv';
                    if (this.getFileName) {
                        fileName = this.getFileName('csv')
                    }
                    tools.downloadReportCSV('data-table', fileName);
                    if (this.afterDownload) this.afterDownload();
                    clearInterval(i);
                }
            }, 200);
        },
        hideIf (field) {
            if (field.hideIf && field.hideIf(this.current)) {
                return true;
            }
        },
        match (h) {
            if (!h) return;
            if (typeof h != 'string') return str;
            return h.replace(/_/g, ' ').replace(
                /\w\S*/g,
                function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        },
        getHeadings () {
            let res = {};
            let self = this;
            for (let name in this.headers) {
                if (this.translateHeaders) {
                    let v = this.match(self.headers[name]);
                    res[name] = tr(v);
                } else {
                    res[name] = self.headers[name];
                }
            }
            if (this.serverColumnsHeader) {
                for (let h of this.serverColumnsHeader) {
                    if (!res[h]) {
                        if (this.translateHeaders) {
                            let v = this.match(h);
                            res[h] = tr(v);
                        } else {
                            res[h] = h;
                        }
                    }
                }
            }
            if (this.headerColumns) {
                for (let h of this.headerColumns) {
                    if (!res[h]) {
                        if (this.translateHeaders) {
                            let v = this.match(h);
                            res[h] = tr(v);
                        } else {
                            res[h] = h;
                        }
                    }
                }
            }
            res.__info__ = '';
            return res;
        },
        getTemplates () {
            let res = {};
            let self = this;
            if (!this.templates) return res;
            for (let field of this.templates) {
                res[field.name] = function(t, row) {
                    return field.callback(row, self.dataList, field.name);
                }
            }
            return res;

        },
        updateCurrent (fieldName) {
            this.$emit('update:current', Object.assign({}, this.current));
            this.$emit('afterEdit', fieldName);
        },
        async afterEdit (rowFieldName, row) {
            let rowList = _.find(this.dataList, (r) => r.id == row.id);
            if (rowList) {
                rowList[rowFieldName] = row[rowFieldName];
            }
            if (this.lastValue) this.lastEdited = _.cloneDeep(this.lastValue);
            if (this.callbacks[rowFieldName]) {
                await this.callbacks[rowFieldName](rowFieldName, row, this);
            }
            if (this.lastValue) {
                this.lastValue.value = row[rowFieldName];
            }
            if (this.lastEdited) {
                this.lastEdited.value = row[rowFieldName];
            }
        },
        getFilteredOptions (row, field) {
            if (field.filterOptions) {
                let r = field.filterOptions(row, _.cloneDeep(this.fieldOptionsTable[field.name]));
                return r;
            }
            return this.fieldOptionsTable[field.name];
        },
        invalidIf (row, field) {
            if (field.invalidIf) {
                return field.invalidIf(row, field.name);
            }
            return false;
        },
        async buttonCallback (callback, id) {
            this.processingCallback[id] = true;
            this.processingCallback = Object.assign({}, this.processingCallback);
            await callback(this)
            this.processingCallback[id] = false;
            this.processingCallback = Object.assign({}, this.processingCallback);
        },
        getFieldColumns (field){
            if (!this.cardView) return 'col report-filter'
            return 'col';
            if (!field.columns) return 'col';
            return 'col-' + field.columns;
        },
        addError (msg) {
            if (msg && msg.detail) {
                this.errors = [tr(msg.detail)];
            } else if (msg && msg.err) {
                this.errors = [tr(msg.err)];
            } else {
                this.errors = [msg];
            }
            this.message = null;
        },
        setProcessing (value) {
            api.setProcessing(value);
        },
        showAll () {
            this.filterColumns = false;
            let cols = this.headerColumns? this.headerColumns: this.serverColumnsHeader
            this.columnsNames = _.cloneDeep(cols);
            this.hiddenFilterColumnNames = null;
            this.hiddenColumns = [];
        },
        hideColumn (text, fieldName) {
            let i = this.columnsNames.indexOf(fieldName);
            if (i>-1) {
                this.columnsNames.splice(i, 1);
                this.hiddenColumns.push(fieldName);
            }
            if (this.hiddenColumns.length>0 && this.columnsNames.indexOf('__info__')==-1) {
                this.columnsNames.splice(0, 0, '__info__');
            }
        },
        hideFilterColumns () {
            this.filterColumns = !this.filterColumns;
            if (this.filterColumns) {
                let names = [];
                for (let fieldName in this.current) {
                    if (!this.current[fieldName]) continue;
                    let i = this.columnsNames.indexOf(fieldName);
                    if (i>-1) {
                        this.columnsNames.splice(i, 1);
                        let value = this.applyTemplate(fieldName, this.dataList[0]);
                        names.push(value)
                    }
                }
                this.columnsNames = Object.assign([], this.columnsNames);
                this.hiddenFilterColumnNames = names.join(', ');

            } else {
                let cols = this.headerColumns? this.headerColumns: this.serverColumnsHeader
                this.columnsNames = _.cloneDeep(cols);
                this.hiddenFilterColumnNames = null;
            }
        },
        applyTemplate (fieldName, row) {
            let value = row[fieldName];
            let template = _.find(this.templates, (t) => t.name==fieldName);
            if (template) {
                value = template.callback(row, this.dataList, fieldName);
            }
            if (this.columnsFormat && this.columnsFormat[fieldName]) {
                value = this.columnsFormat[fieldName](value, row);
            }
            return value;
        },
        getHiddenColumns (row) {
            let names = [];
            for (let c of this.hiddenColumns) {
                let value = this.applyTemplate(c, row);
                names.push(value);
            }
            return names.join(', ');
        },
        disableSorting (fields) {
            let res = {}
            let self = this;
            for (let fieldName of fields) {
                res[fieldName] = function (ascending) {
                    return function(a, b){
                        return 0;
                        return 0;
                    }
                }
            }
            return res;
        },
        getCustomSorting (fields) {
            let res = {}
            let self = this;
            for (let fieldName of fields) {
                res[fieldName] = function (ascending) {
                    return function(a, b){
                        let _b = b[fieldName]? b[fieldName]: '';
                        let _a = a[fieldName]? a[fieldName]: '';

                        if (ascending) {
                            if (a.__total_row__) return 1;
                            if (_a > _b) return -1;
                            if (_a < _b) return 1;
                            return 0    ;
                        } else {
                            if (a.__total_row__) return 1;
                            if (_b > _a) return -1;
                            if (_b < _a) return 1;
                            return 0;
                        }

                    }
                }
            }
            return res;
        },
        getFieldClass (row, fieldName) {
            if (row.__total_row__ && this.totalsOn && this.totalsOn.indexOf(fieldName)>-1) return 'report-total-row text-right';
            if (row.__total_row__ && this.averageOn && this.averageOn.indexOf(fieldName)>-1) return 'report-total-row text-right';
            if (row.__total_row__) return 'report-total-row';
            if (!this.fieldClasses) return;
            if (!this.fieldClasses[fieldName]) return;
            return this.fieldClasses[fieldName](row);
        },
        getFieldStyle (row, fieldName) {
            if (!this.fieldStyles) return '';
            if (!this.fieldStyles[fieldName]) return '';
            return this.fieldStyles[fieldName](row);
        },
        chart (chart) {
          return {
            labels: this.chartDataList(chart),
            datasets: this.chartDataValues(chart)
          }
        },
        focusOn (value, fieldName, rowFieldName, rowNr) {
            this.lastValue = {value: value, rowFieldName: rowFieldName, rowNr: rowNr-1};
        },
        handleError (error) {
            this.addError(error);
            api.setProcessing(false);
            if (this.errorCallback) {
                this.errorCallback(this, error);
            }
        },
        getCardColumns () {
            if (!this.$parent) return;
            if (!this.$parent.$parent) return;
            if (!this.$parent.$parent.$options) return;
            if (!this.$parent.$parent.$options.propsData) return;
            if (!this.$parent.$parent.$options.propsData.card) return;
            return (this.$parent.$parent.$options.propsData.card.columns);
        },
        onRowClick (data) {
            if (this.rowClick) {
                this.rowClick(data.row);
            }
        },
        columnRowClick (row, fieldName) {
            if (this.columnClick && this.columnClick[fieldName]) {
                this.columnClick[fieldName](row, this);
            }
        },
        getParentName (report) {
            let name;
            if (report.$options.mixins) {
                //name = report.$options.mixins[0].name;
            }
            if (!name) {
                name = report.$options.name;
            }
            return _.map(name.split('-'), (r) => {
                return tools.toTitleCase(r);
            }).join('');
        },
        getInDashboard () {
            let name;
            try {
                //name = this.$parent.$options.__file.split('/').at(-1).replace('.vue', '');
                name = this.$parent.$options.__name;
                //console.log(2, name, this.$parent.$options.__file)
            } catch(ex) {
                //console.log(ex)
            }
            if (!name) {
                name = this.getParentName(this.$parent);
            }
            let dashboard = localStorage.getItem('dashboard');
            if (dashboard) dashboard = JSON.parse(dashboard);
            if (!dashboard) dashboard = [];
            let index = _.findIndex(dashboard, (r) => {
                return r.component == name;
            });
            return {name, index, dashboard};
        },
        expandCard () {
            let r = this.getInDashboard();
            let dashboard = r.dashboard;
            if (!dashboard[r.index]) return;
            dashboard[r.index].columns = 12;
            this.cardColumns = 12;
            localStorage.setItem('dashboard', JSON.stringify(dashboard));
            let div = $('#' + dashboard[r.index].component);
            if (div) {
                div.toggleClass('col-md-6');
                div.toggleClass('col-md-12');
            }
        },
        compressCard () {
            let r = this.getInDashboard();
            let dashboard = r.dashboard;
            dashboard[r.index].columns = 6;
            this.cardColumns = 6;
            localStorage.setItem('dashboard', JSON.stringify(dashboard));
            let div = $('#' + dashboard[r.index].component);
            if (div) {
                div.toggleClass('col-md-6');
                div.toggleClass('col-md-12');
            }
        },
        addToDashboard () {
            let r = this.getInDashboard();
            let dashboard = r.dashboard;
            let required = tools.checkFields(this, this.current);
            if (required && r.index==-1) return;
            if (r.index==-1) {
                let p = {
                    hideFilters: true,
                    runMounted: true,
                    cardView: true,
                    reportViewClass: 'card-report-view',
                    notFilterable: true,
                    perPage: 8,
                    hideSearch: true,
                }
                for (let field of this.fields) {
                    if (!this.current[field.name]) continue;
                    p[field.name] = this.current[field.name];
                    if (field.editor == 'date') {
                        let days = moment(this.current[field.name]).diff(moment(new Date()), 'days');
                        p[field.name] = {days}
                    }
                }
                dashboard.push({component: r.name, folder: 'components/reports', columns: 12,
                    props: {options: p}});
                this.inDashboard = true;
            } else {
                dashboard.splice(r.index, 1);
                this.inDashboard = false;
            }
            localStorage.setItem('dashboard', JSON.stringify(dashboard));
        },
        applyTotals (dataValues) {
            if (!this.showTotals) return dataValues;
            let sep = ','
            if (this.user && this.user.DecimalSeparator) sep = this.user.DecimalSeparator;
            let dataList = Object.assign([], dataValues);
            let row = {};
            let add = false;
            if (this.totalLabel) {
                add = true;
                for (let n of this.totalLabel) {
                    row[n] = tr('TOTAL');
                }
            }
            if (this.averageLabel) {
                add = true;
                for (let n of this.averageLabel) {
                    row[n] = tr('AVERAGE');
                }
            }
            let self = this;
            if (this.totalsOn || this.averageOn) {
                add = true;
                let totalsOn = this.totalsOn || [];
                let averageOn = this.averageOn || [];
                let fields = [...totalsOn, ...averageOn];
                for (let name of fields) {
                    let template = _.find(this.templates, (t) => t.name==name);
                    let sum = dataList.reduce(function(s, f) {
                        let value = f[name];
                        if (template) {
                            let vTemplate = template.callback(f, self.dataList, name);
                            if (typeof vTemplate === 'string') {
                                value = parseFloat(vTemplate.replace(new RegExp(sep, 'g'), ''));
                            } else if (typeof vTemplate === 'number') {
                                //value = null;
                            } else {
                                value = null;
                            }
                        }
                        if (value) return s + value;
                        return s;
                    }, 0);
                    row[name] = sum;
                    row.calculated = true;
                    row.__total_row__ = true;
                }
            }
            if (this.countOn) {
                add = true;
                for (let name of this.countOn) {
                    let count = dataList.length;
                    row[name] = 'Total: ' + count;
                    row.calculated = true;
                    row.__total_row__ = true;
                }
            }
            if (this.averageOn) {
                add = true;
                for (let name of this.averageOn) {
                    let count = dataList.length;
                    row[name] = (row[name] / count).toFixed(1);
                    row.calculated = true;
                    row.__total_row__ = true;
                }
            }
            if (add) dataList.push(row);
            return dataList;
        },
        getFilterValue (field) {
            let selectEditor;
            if (field.editor=='select') selectEditor = true;
            if (field.editor=='vue-select') selectEditor = true;
            if (selectEditor && this.fieldOptions[field.name]) {
                let row = _.find(this.fieldOptions[field.name], (r) => r.value == this.current[field.name]);
                if (row) return tr(row.label);
            }
            if (field.editor=='date') {
                return moment(this.current[field.name], "YYYY-MM-DD").format("DD/MM/YYYY")
            }
            return this.current[field.name]
        },
        columnsToShowRow (row) {
            let res = [];
            for (let fieldName of this.columnsToShow) {
                let add = true;
                if (this.setRowSpan && row.HideFields && row.HideFields.indexOf(fieldName)>-1) add = false;
                if (add) res.push(fieldName);
            }
            return res;
        },
        getColumnCode (c, i) {
            let index = this.columnsToShow.indexOf(c);
            return `${alphabet[index]}${i + 1}`;
        }
    },
    watch: {
        current: {
            handler () {
                this.showTotals = false;
            },
            deep: true,
        },
        updateReport () {
            this.run();
        },
        serialFx () {
            this.applyChartFunctions();
        },
        serialY () {
            this.applyChartFunctions();
        }
    }
}
</script>

<style scoped>
.report-filter {
    max-width: 200px !important;
}
</style>